import React from "react";
import "./footer.css"
import { useNavigate } from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();
    
    const handleClick = (redirect) => {
        navigate(`/${redirect}`);
   };

    const contact_list = [
        {
            name: "Discord",
            link: "https://www.discord.com/users/320542523586117632",
        },
        {
            name: "Github",
            link: "https://github.com/spikethatmike",
            imagePath: "github.ico"
        },
        {
            name: "Fiverr",
            link: "https://fiverr.com/spikethatmike",
        },
    ]

    return (
        <section id="footer">
            <div className="image">
                <img src="images/pacfish-circle-cropped.png" alt="Profile picture" />
                <a className="back-to-top" href="#">&#x2191; Back to Top</a>
            </div>

            {/* {contact_list.map((c, index) => <Contacts item={c} key={index}/>)} */}

            <div>
                <a className="site" href="#features" onClick={() => handleClick("")}>Features</a>
                <a className="site" href="" onClick={() => handleClick("commands")}>Commands</a>
                <a className="site" href="" onClick={() => handleClick("premium")}>Premium</a>
                <a className="site" href="#community" onClick={() => handleClick("")}>Community</a>
                {/* <a className="site" href="#more" onClick={() => handleClick("")}>More</a> */}
            </div>

            <div>
                <a className="site" href="" onClick={() => handleClick("tos")}>Terms of Service</a>
                <a className="site" href="" onClick={() => handleClick("privacy-policy")}>Privacy Policy</a>
                <a className="site" href="" onClick={() => window.location.href = "https://music.pacfish.xyz"}>Music</a>
            </div>

            <p className="copyright" style={{margin: "0px", marginTop: "45px"}}>&copy; 2023 <a href="https://pacfish.xyz">pacfish.xyz</a>. All rights reserved.</p>
            <p className="copyright" style={{margin: "0px", marginTop: "5px"}}>Pacfish is not affiliated with Discord in any way.</p>
        </section>

    )
}