import { useEffect } from "react";

export default function Discord() {
    useEffect(() => {
        window.location.href = "https://discord.gg/PtpvRXK6HA";
    });
    return (
        <>
            Redirecting...
        </>
    )
}
