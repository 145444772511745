import React from "react";
import Card from "./card";
import "./features.css"
import {useNavigate} from 'react-router-dom'

export default function Features() {
    // const navigate = useNavigate();
    const features = [
        { title: "Tickets", description: "A customisible ticketing system for your server!", imagePATH: "pacfish.png" },
        { title: "Suggestions", description: "Let your members create and rate suggestions for your server!", imagePATH: "pacfish.png" },
        { title: "Giveaways", description: "Start giveaways for your server members to participate in and win prizes!!", imagePATH: "pacfish.png" },
        { title: "Mini games", description: "Play mini-games within your server. Great for game nights!", imagePATH: "pacfish.png" },
        { title: "Moderation", description: "Ban, Kick, Timeout, Slowmode", imagePATH: "pacfish.png" },
        { title: "Reaction Roles V2", description: "Let your members obtain server roles with message buttons!", imagePATH: "pacfish.png" },
    ];
    return (
        <section id="features">
            <div>
                <p className="section-title">Features</p>
            </div>
            <div className="feature-cards">
                {features.map((feature, i) => {
                    return <Card key={i} index={i} feature={feature}/>
                })}
            </div>
            <div className="features-button">
                Want access to these amazing features? Invite Pacfish today!<br/>
                <button onClick={() => window.open("https://pacfish.xyz/invite", '_blank').focus()}>
                    Invite
                </button>
            </div>
        </section>

    )
}