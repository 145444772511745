import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Error404 from '../../components/error404';
import Commands from './commands/commands';
import Discord from './discord/discord';
import Home from './home/home';
import Invite from './invite/invite';
import Premium from './premium/premium';
import PrivacyPolicy from './privacyPolicy/privacyPolicy';
import TOS from './tos/tos';

export default function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/tos" element={<TOS/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/commands" element={<Commands/>}/>
                    <Route path="/premium" element={<Premium/>}/>
                    <Route path="/invite" element={<Invite/>}/>
                    <Route path="/discord" element={<Discord/>}/>
                    <Route path="*" element={<Error404/>}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}