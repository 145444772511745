import "./App.css";
import DashboardApp from "./subs/dashboard/App";
import MusicApp from "./subs/music/App";
import MainApp from "./subs/main/App";

export default function App() {
    return (
        <>
            {(window.location.host.split(".")[0] === "dashboard") ? (
                <DashboardApp/>
            ) : (window.location.host.split(".")[0] === "music") ? (
                <MusicApp/>
            ) : (
                <MainApp/>
            )}  
        </>
    )
}