import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import "./tos.css";

export default function TOS() {
    return (
        <>
            <Navbar/>
            <section id="tos">
                <div>
                    <p className="section-title title-margin">Terms of service</p>
                    <p className="terms-of-service">
                    By inviting Pacfish or Pacfish music to your discord server you agree that you have read, understood, and accepted these terms.<br/>
                    You are also responsible for informing the members in your discord server about these terms.<br/>
                    If you do not agree with any of these terms, you are prohibited from using or adding Pacfish bots to your server.<br/>
                    <br/>
                    <span className="tos-title">Disclaimer</span>
                    <br/>
                    You are strictly prohibited to use Pacfish against the ToS of discord or for illegal purposes.<br/>
                    If you find disocrd users / servers using Pacfish bots against this terms of service or discords ToS, please send us an email to <a href="mailto:contact@pacfish.xyz">contact@pacfish.xyz</a> or create a ticket in our offical discord support server (<a href="https://discord.pacfish.xyz">https://discord.pacfish.xyz</a>).<br/>
                    <br/>
                    <span className="tos-title">Availability</span>
                    <br/>
                    Pacfish or Pacfish music is provided as-is. There are no guarantees that it will be available in the future, and its purpose or availability may be changed at any time.<br/>
                    User / Server related data may be deleted at any time.<br/>
                    User / Server related data is non-transferable between discord accounts / servers.<br/>
                    Any premium features are not guaranteed, they may change or be revoked at any time.<br/>
                    If any premium features are being removed, we will give a notice in the offical discord support server which is available for all users (<a href="https://discord.pacfish.xyz">https://discord.pacfish.xyz</a>).<br/>
                    Access to all or specific features of Pacfish may be revoked, for all or a specific user / server, at any time.<br/>
                    <br/>
                    <span className="tos-title">Refund Policy</span>
                    <br/>
                    Refunds for premium payments may be issued when the user requests it and one of the following requirements is met:<br/>
                    <ul>
                        <li>It's the users first premium payment for any guild & the payment was made on the same day the user requests for a refund</li>
                        <li>A major premium feature was removed and the user bought premium specifically because of that feature</li>
                        <li>The user gives an explanation of the situation</li>
                    </ul>
                    <br/>
                    Refunds for payments won't be issued if one of the following requirements is met:<br/>
                    <ul>
                        <li>The user accidentally purchases premium for the wrong discord server</li>
                        <li>The user has broken the Terms of Service</li>
                    </ul>
                    Refunds wont be issued if you have broken our Terms of Service.<br/>
                    <br/>
                    You can request a refund by sending us an email to <a href="mailto:contact@pacfish.xyz">contact@pacfish.xyz</a> or create a ticket in our offical discord support server (<a href="https://discord.pacfish.xyz">https://discord.pacfish.xyz</a>).<br/>
                    <br/>
                    <span className="tos-title">Chargeback Policy</span>
                    <br/>
                    Chargeback on premium payments is not tolerated, you may seek a refund by creating a ticket in our offical discord support server (<a href="https://discord.pacfish.xyz">https://discord.pacfish.xyz</a>).<br/>
                    If you (the discord server owner) charge back on a premium payment, Pacfish will block your server.<br/>
                    You can only get your discord server unblocked by repaying the chargeback amount.<br/>
                    <br/>
                    Last Updated: 02.01.2023
                    </p>
                </div>
                <div className="line-break"></div>
                <div className="changelog">
                    <p className="section-title" style={{marginBottom: "0px", fontSize: "36px"}}>Changes - 02.01.2023</p>
                    <p className="terms-of-service">
                    <ul>
                        <li>Added terms of service</li>
                    </ul>
                    </p> 
                </div>
            </section>
            <Footer/>
        </>
    )
}
