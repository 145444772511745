import { useEffect } from "react";

export default function Invite() {
    useEffect(() => {
        window.location.href = "https://discord.com/api/oauth2/authorize?client_id=924077301828825171&permissions=8&scope=bot%20applications.commands";
    });
    return (
        <>
            Redirecting...
        </>
    )
}
