import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import "./premium.css";
import { useNavigate } from "react-router-dom";

export default function Premium() {
    const navigate = useNavigate();
    return (
        <>
            <Navbar/>
                <section id="premium">
                    <p className="section-title title-margin">
                        Premium
                    </p>
                    <p className="premium-desc">
                        Want more for your server at a low low price?
                    </p>
                    <p className="premium-desc">
                        Pacfish premium has your back!
                    </p>
                    <p className="premium-desc">
                        Check out all of the premium benefits below!
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <div className="premium-card">
                        <div className="premium-card-title">
                            Free<br/>
                            <span className="premium-prices">£0 per month</span>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">25+ Free commands</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-crosssign">
                                    <div class="crosssign_circle"></div>
                                    <div class="crosssign_stem"></div>
                                    <div class="crosssign_stem2"></div>
                                </span>
                                <p className="item-text">5+ Premium commands</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">Up to 25 active tickets</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">Up to 3 active giveaways</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <div className="item-text">Up to 25 active suggestions</div>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <div className="item-text">Up to 5 reaction roles</div>
                            </div>
                        </div>
                    </div>
                    <div className="premium-verus">
                        <b>Vs</b>
                    </div>
                    <div className="premium-card">
                        <div className="premium-card-title premium-background">
                            Premium<br/>
                            <span className="premium-prices">£5 per month </span>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">25+ Free commands</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">5+ Premium commands</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">Up to 50 active tickets</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">Up to 10 active giveaways</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">Up to 100 active suggestions</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <div className="item-text">Up to 25 reaction roles</div>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-checkmark">
                                    <div class="checkmark_circle"></div>
                                    <div class="checkmark_stem"></div>
                                    <div class="checkmark_kick"></div>
                                </span>
                                <p className="item-text">Supports Pacfish's development</p>
                            </div>
                        </div>
                        <div className="premium-feature-list">
                            <div className="item">
                                <span class="item-crosssign">
                                    <div class="crosssign_circle"></div>
                                    <div class="crosssign_stem"></div>
                                    <div class="crosssign_stem2"></div>
                                </span>
                                <p className="item-text">Read you a bedtime story</p>
                            </div>
                        </div>
                    </div>
                    <div className="premium-line"/>
                    <div>
                        <p className="premium-buy">
                            Want to purchase premium?<br/>
                            Invite Pacfish today!
                        </p>
                        <button className="premium-button" onClick={() => navigate("/invite")}>
                            Invite
                        </button>
                        <p className="premium-buy-instructions">
                            To purchase premium, use the <span className="premium-command">/premium</span> command in discord and follow the instructions!<br/>
                            <span className="premium-server-owners"><i>*This command is only for server owners.</i></span>
                        </p>
                    </div>
                </section>
            <Footer/>
        </>
    )
}
