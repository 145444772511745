import "./command.css"
import { useState } from "react";

export default function Command({ command }) {
    
    const [toggle, setToggle] = useState(false);

    const handleClick = () => {
        setToggle(toggle => !toggle);
    }
    return (
        <>
            <div className={`command-card ${(toggle) ? "dropdown" : ""}`} onClick={handleClick}>
                <p className="cmd-card-info">
                    <span className="cmd-card-title">{command.name} -</span> <span className="cmd-card-desc">{command.description} </span> <span class="arrow"></span>
                </p>
                <p className="cmd-card-extra">
                    {(command.subcommands.length > 0) ? (
                    <>
                        <span className="cmd-card-sub-title">Subcommands:</span><br/>
                        <span className="cmd-card-subs">{command.subcommands.join(", ")}</span>
                    </>)
                    : ""
                    }
                </p>
            </div>
        </>
    )
}