import { useEffect, useState } from "react";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import Category from "./components/category/category";
import "./commands.css";
import Command from "./components/command/command";

export default function Commands() {
    const [cmdList, setCmdList] = useState({});
    
    const categories = [
        { category: "All" },
        { category: "Tickets", name: "ticket", description: "Manage an active ticket", subcommands: ["adduser", "close", "createtranscript", "info", "lock", "removeuser", "unlock"]},
        { category: "Suggestions", name: "suggestion", description: "Create or manage an active suggestion", subcommands: []}
    ];

    useEffect(() => {
        setCmdList([...categories].filter((x) => x.category != "All"))
    }, []);

    const handleClick = () => {
        const categorys = document.getElementsByClassName("category");
        for (const item of categorys) {
            console.log("non-active")
            if(item.classList.contains("activeCategory")) {
                const commands = categories.filter((x) => ((x.category == item.innerHTML) || item.innerHTML == "All") && x.category != "All");
                setCmdList(() => [...commands]);
            }
        }
    };

    return (
        <>
            <Navbar/>
            <section id="commands">
                <div>
                    <p className="section-title title-margin">Commands</p>
                </div>
                <div>
                    <p className="commands-desc">
                        Want to check out all of Pacfish's commands?
                        <br/>
                        Well your in the right place!
                        <br/>
                        A list of all available commands and their descriptions will be found below.
                    </p>
                </div>
                <div className="break"></div>
                <div className="categories" onClick={handleClick}>
                    {[...new Set(categories.map(item => item.category))].map((category, i) => {
                        return <Category key={i} index={i} cat={category}/>
                    })}
                </div>
                <p>List of commands:</p>
                <div className="commands">
                    {   
                        (cmdList.length > 0) ?
                            cmdList?.map((command, i) => {
                                return <Command key={i} index={i} command={command}/>
                            })
                            :
                            ""
                    }
                </div>
            </section>
            <Footer/>
        </>
    )
}