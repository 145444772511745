import Commands from "./components/commands/commands";
import Features from "./components/features/features";
import Intro from "./components/intro/intro";
import More from "./components/more/more";
import Navbar from "../components/navbar/navbar";
import Premium from "./components/premium/premium";
import Support from "./components/support/support";
import Footer from "../components/footer/footer";

export default function Home() {
    return (
        <>
            <Navbar/>
            <div className="break"></div>
            <Intro/>
            <div className="break"></div>
            <div className="line-break"></div>
            <Features/>
            <div className="break"></div>
            <div className="line-break"></div>
            {/* <Commands/>
            <div className="break"></div>
            <div className="line-break"></div>
            <Premium/>
            <div className="break"></div>
            <div className="line-break"></div> */}
            <Support/>
            <div className="break"></div>
            <div className="line-break"></div>
            {/* <More/>
            <div className="break"></div> */}
            <Footer/>
        </>
    )
}