import React from "react";
import "./card.css"

export default function Card({ feature }) {
    
    return (
        <div className="card">
            <div className="card-content">
                <img className="card-image" src={`images/${feature.imagePATH}`} alt={`${feature.title} image`}/>
                <br/>
                <p className="card-title">{feature.title}</p>
                <p className="card-desc">{feature.description}</p>
            </div>
        </div>

    )
}