import { useState } from "react";
import "./category.css"

export default function Category({ cat }) {
    const handleClick = (e) => {
        const categories = document.getElementsByClassName("category");
        for (const item of categories) {
            item.classList.remove("activeCategory");
        }
        e.currentTarget.classList.add("activeCategory");
    }
    

    return (
        <>
            <li className={(cat == "All" ? "category activeCategory" : "category")} onClick={handleClick}>
                {cat}
            </li>
        </>
    )
}