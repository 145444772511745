import React from "react";
import { useNavigate } from "react-router-dom";
import "./error404.css"

export default function Error404() {
    const history = useNavigate();
    
    const goBack = () => {
        history(-1);
    }

    const home = () => {
        history("/");
    }

    return (
        <section className="error404-container">
            <div className="error404-msg">
                <h1>The page you are looking for does not exist!</h1>
            </div>
            <div className="error404-buttons">
                <button className="error404-button" onClick={home}>Home</button>
                <button className="error404-button" onClick={goBack}>Go back</button>
            </div>
        </section>

    )
}