import React from "react";
import "./support.css"
// import {useNavigate} from 'react-router-dom'

export default function Support() {
    // const navigate = useNavigate();
    const handleClick = () => {
        // navigate("/discord");
        window.open("https://pacfish.xyz/discord", '_blank').focus();
    };
    return (
        <section id="community">
            <div>
                <p className="section-title">Join the pacfish community</p>
            </div>
            <div>
                <p className="support-desc">
                    Have any questions or need to report a bug?
                    <br/>
                    Well your in the right place! Click below to be redirected to our discord support server.
                </p>
                <button className="support-button" onClick={handleClick}>
                   Join
                </button>
            </div>
        </section>
    )
}