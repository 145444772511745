import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import "./privacyPolicy.css";

export default function PrivacyPolicy() {
    return (
        <>
            <Navbar/>
            <section id="tos">
                <div>
                    <p className="section-title">Privacy Policy</p>
                    <p className="terms-of-service">
                    At Pacfish, we are committed to protecting the privacy of our users.<br/>
                    This privacy policy explains how we collect, use, and protect the personal information of users of the Pacfish Discord bot.<br/>
                    <br/>
                    <span className="tos-title">Information We Collect</span>
                    <br/>
                    Pacfish does not collect any personal information from users, however we may collect the following information from users & servers:
                    <ul>
                        <li>Discord server information: This includes the server name and server ID.</li>
                        <li>Discord user IDs: If a user purchases premium for their Discord server, we will store their Discord user ID and the transaction ID for the purchase. This information is used for billing and account management purposes only.</li>
                        <li>Message/Interaction IDs: Pacfish may collect the ID of messages/interactions sent through the bot.</li>
                        <li>Channel IDs: Pacfish may collect the ID of channels in which the bot is used.</li>
                        <li>Role IDs: Pacfish may collect the ID of roles associated with the bot.</li>
                        <li>Ticket transcripts: If a user creates a support ticket through the bot, we may collect the transcript of the ticket conversation.</li>
                        <li>Temporary storage of giveaway information: If a user creates a giveaway through the bot, we may temporarily store information about the giveaway until it is ended.</li>
                        <li>Temporary storage of suggestion information: If a user submits a suggestion through the bot, we may temporarily store the suggestion until it is accepted or rejected.</li>
                        <li>Usage statistics: For example, how many times a command or specific feature gets used by a user / server.</li>
                    </ul>
                    <br/>
                    <span className="tos-title">Use of Collected Information</span>
                    <br/>
                    We use the information collected through Pacfish to operate, maintain, and improve the bot.<br/>
                    This includes using the server and user information to track usage and diagnose any issues that may arise.<br/>
                    We may use usage statistics for statistical analysis to determine the most popular features.<br/>

                    We do not share any of the information collected through Pacfish with third parties, except in the case of aggregated or anonymized data that cannot be traced back to individual users.<br/>
                    Access to all or specific features of Pacfish may be revoked, for all or a specific user / server, at any time.<br/>
                    <br/>
                    <span className="tos-title">Protection of Collected Information</span>
                    <br/>
                    We take appropriate security measures to protect the information collected through Pacfish against unauthorized access, disclosure, alteration, or destruction.<br/>
                    These measures include the use of secure servers.<br/>
                    <br/>
                    <span className="tos-title">User Rights</span>
                    <br/>
                    Users of Pacfish have the right to request for access, to rectify, or to delete their discord server information at any time.<br/>
                    If you would like to exercise these rights, please contact us through the Discord server (<a href="https://discord.pacfish.xyz">https://discord.pacfish.xyz</a>) or email address (<a href="mailto:contact@pacfish.xyz">contact@pacfish.xyz</a>).<br/>
                    <br/>
                    <span className="tos-title">Contact Us</span>
                    <br/>
                    If you have any questions or concerns about our privacy policy or the information we collect through Pacfish, please contact us through the Discord server (<a href="https://discord.pacfish.xyz">https://discord.pacfish.xyz</a>) or email address (<a href="mailto:contact@pacfish.xyz">contact@pacfish.xyz</a>).<br/> 
                    <br/>
                    Last Updated: 02.01.2023
                    </p>
                </div>
                <div className="line-break"></div>
                <div className="changelog">
                    <p className="section-title" style={{marginBottom: "0px", fontSize: "36px"}}>Changes - 02.01.2023</p>
                    <p className="terms-of-service">
                    <ul>
                        <li>Added privacy policy</li>
                    </ul>
                    </p> 
                </div>
            </section>
            <Footer/>
        </>
    )
}
