import React from "react";
import "./intro.css"

export default function Intro() {
    
    return (
        <section id="intro">
            <div class="intro-image">
                <img src="images/pacfish-small-no-bg.png" alt="Pacfish logo"/>
            </div>
            <br/><br/>
            <div>
                <p className="intro-title">Pacfish</p>
                <p className="intro-desc">
                    The discord bot which has all of your favourite features.
                    <br/>
                    All in one place.
                </p>
            </div>
            <br/>
            <div>
                <p className="intro-stats">
                    Trusted by <span>1</span> users and <span>2</span> guilds.
                </p>
            </div>
        </section>

    )
}