import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./navbar.css"

export default function Navbar() {
    const [toggle, setToggle] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleNav = () => {
        setToggle(toggle => !toggle);
    }

    const navigate = useNavigate();

    const handleClick = (redirect) => {
        setToggle(false);
        navigate(`/${redirect}`);
    };

    const scrollHandler = () => {
        window.scrollY > 10
            ? setScrolled(true)
            : setScrolled(false)
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
      
        return () =>
          window.removeEventListener('scroll', scrollHandler);
    })
    
    return (
        <>
            <nav className={(scrolled) ? "navbar scrolled" : "navbar"} style={{"height": (toggle) ? "auto" : "60px"}}>
                <a className="brand" href="/">
                    <img src="images/pacfish-circle-cropped.png" alt="Pacfish logo"/>
                    <span>Pacfish</span>
                </a>
                <div className="hamburger" onClick={toggleNav}>
                    <span className={`hamburger-bar ${(toggle) ? "x" : ""}`}></span>
                    <span className={`hamburger-bar ${(toggle) ? "x" : ""}`}></span>
                    <span className={`hamburger-bar ${(toggle) ? "x" : ""}`}></span>
                </div>
                <ul className={`navbar-list ${(toggle) ? "active" : ""}`}>
                    <li className="navbar-item"><a href="#features" onClick={() => handleClick("")}>Features</a></li>
                    <li className="navbar-item"><a href="" onClick={() => handleClick("commands")}>Commands</a></li>
                    <li className="navbar-item"><a href="" onClick={() => handleClick("premium")}>Premium</a></li>
                    <li className="navbar-item"><a href="#community" onClick={() => handleClick("")}>Community</a></li>
                    {/* <li className="navbar-item"><a href="#more" onClick={() => handleClick("")}>More</a></li> */}
                    <button className="navbar-button login" onClick={() => window.location.href = "https://dashboard.pacfish.xyz"}>
                    Login
                    </button>
                    <button className="navbar-button invite" onClick={() => window.open("https://pacfish.xyz/invite", '_blank').focus()}>
                    Invite
                    </button>
                </ul>
            </nav>
        </>
    )
}